import React, { useCallback, useMemo, CSSProperties, useState, useReducer, Fragment } from 'react'
import { useDropzone } from 'react-dropzone'
import { css } from '@emotion/core'
import filesize from 'filesize'
import styled from '@emotion/styled';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const FileType = require('file-type/browser');

const LogListItem = styled.li`
    
`
const ErrorListItem = styled.li`
    color: red;
`

const baseStyle: CSSProperties = {
    height: '100%',
    minHeight: '20rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 5,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    backgroundColor: '#eee',
    borderRadius: '1rem',
    boxShadow: '1px #000'
};

const activeStyle: CSSProperties = {
    borderColor: '#2196f3'
};

const acceptStyle: CSSProperties = {
    borderColor: '#00e676'
};

const rejectStyle: CSSProperties = {
    borderColor: '#ff1744'
};

type Log = {
    filename: String
    status: LogStatus
    size: number
    error: String | null
}

enum LogStatus {
    SUCCESS,
    IN_PROGRESS,
    ERROR
}

type NewLogAction = {
    type: 'add'
    log: Log
}

function logReducer(state: Log[] = [], action: NewLogAction) {
    switch (action.type) {
        case 'add':
            return [...state, action.log];
        default:
            return state;
    }
}


export const MyDropzone: React.FC<{ sessionId: number, children: JSX.Element[] }> = ({ sessionId, children }) => {
    const [sessionName, setSessionName] = useState('')
    const [logState, logDispatch] = useReducer(logReducer, []);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file: File) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async (event) => {
                console.log(event);
            }
            reader.onloadend = async (_event) => {
                new Promise((resolve, reject) => {
                    if (executeRecaptcha)
                        resolve(executeRecaptcha("file_upload"))
                    else
                        reject("Unable to initialize reCAPTCHA")
                }).catch(e => {
                    console.error(e)
                    throw e;
                })
                    .then(token => {
                        console.log("token", token);
                        return fetch("https://8bny2qvbjg.execute-api.ap-southeast-1.amazonaws.com/prod",
                            {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    file: file.name,
                                    contentType: file.type,
                                    sessionId,
                                    token
                                })
                            })
                            .then(response => { console.log(response); return response })
                            .then(response => response.json())
                            .then(response => { console.log(response); return response })
                            .catch(e => {
                                console.error(e)
                                throw new Error("Cannot authenticate session")
                            })
                    })
                    .then(({ sessionName, url }: { sessionName: string, url: string }) => {
                        setSessionName(sessionName)
                        var headers: { [key: string]: string } = {};
                        if (file.type !== '')
                            headers['Content-Type'] = file.type
                        return fetch(url, {
                            method: 'PUT',
                            headers,
                            body: reader.result
                        })
                            .then(_data => {
                                logDispatch({
                                    type: 'add',
                                    log: {
                                        filename: file.name,
                                        size: file.size,
                                        status: LogStatus.SUCCESS,
                                        error: null
                                    }
                                })
                            })
                            .catch(e => {
                                console.log(e)
                                throw new Error("Network error while uploading file to S3")
                            })

                    }).catch((e: Error) => {
                        logDispatch({
                            type: 'add',
                            log: {
                                error: e.message,
                                filename: file.name,
                                size: file.size,
                                status: LogStatus.ERROR
                            }
                        })
                    })
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                console.log(binaryStr)
            }
            reader.readAsArrayBuffer(file)
        })

    }, [])
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);


    const rootProps = getInputProps();

    return (
        <div {...getRootProps({ style })} css={css`outline: 0;`}>
            <input {...getInputProps()} />

            <h2>
                {sessionName
                    ? <>Reference: {sessionName}</>
                    : <>Send me some files...</>
                }
            </h2>
            {isDragActive ?
                <p>Drop files here</p>
                : <p>Drop some files here, or click this area to select</p>
            }
            <div>
                <ul>
                    {
                        logState.map((log, idx) => {
                            const size = filesize(log.size)
                            return <Fragment key={idx}>
                                {log.status !== LogStatus.ERROR
                                    ? <LogListItem><strong>{log.filename}</strong> ({size}) uploaded </LogListItem>
                                    : <ErrorListItem>Error uploading <strong>{log.filename}</strong> ({size}): {log.error}</ErrorListItem>
                                }
                            </Fragment>
                        })
                    }
                </ul>
            </div>
        </div>
    )
}