import * as React from 'react'
import { MyDropzone } from '../components/MyDropzone'
import PageWithoutPadding from '../components/PageWithoutPadding'
import IndexLayout from '../layouts'


const sessionId = new Date().getTime();

const IndexPage = () => {

  return (
    <IndexLayout>
      <PageWithoutPadding>
        <MyDropzone sessionId={sessionId} >
        </MyDropzone>
      </PageWithoutPadding>
    </IndexLayout>
  )
}

export default IndexPage
